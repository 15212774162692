import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout } from '../components/layout';
import Seo from '../components/seo';
import { NotFound } from '../components/NotFound';

const NotFoundPage = ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t('trans.pageNotFound')} lang={pageContext.language} />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
