import { makeStyles } from '@material-ui/core';

export const useListStyles = makeStyles(() => ({
  dot: {
    backgroundColor: ({ dotColor }) => dotColor || '#263d86',
    height: ({ size }) => (size === 'large' ? '18px' : '14px'),
    width: ({ size }) => (size === 'large' ? '18px' : '14px'),
    marginTop: ({ size }) => (size === 'large' ? '7px' : '5px'),
    marginRight: '10px',
    borderBottomStyle: 'solid',
    borderColor: '#efefef',
  },
  contentList: {
    fontSize: ({ size }) => (size === 'large' ? '1.1rem' : '0.8rem'),
    color: ({ size }) => (size === 'large' ? '#080c2d' : '#545372'),
    margin: ({ size }) => (size === 'large' ? '10px 2px 2px 2px' : '2px 2px 2px 2px'),
    display: 'flex',
    textAlign: 'left',
    fontWeight: '400',
    letterSpacing: '0.02rem',
  },
}));
