import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { seoStyles } from './styles';
import ImagesSite404 from '../imagesSettings/Tour/imagesSite404';
import { ListingDotBlue } from '../ListingDotBlue/ListingDotBlue';

export const NotFound = () => {
  const { t } = useTranslation();
  const { backgroundImageCallCenter } = useStaticQuery(
    graphql`
      query {
        backgroundImageCallCenter: file(relativePath: { eq: "heroBackground.jpeg" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );

  const pluginImage = getImage(backgroundImageCallCenter);
  const classes = seoStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={9} md={9} xs={12}>
            <Box className={classes.boxImagesNoContent}>
              <Typography className={classes.siteTitle} />
              {/* {t('trans.privacyPolicyHead')} */}
            </Box>
          </Grid>
        </Grid>
      </BgImage>

      <Container>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={5} className={classes.box}>
            <Typography className={classes.siteTitleContent}>{t('Sorry the page was not found')}</Typography>
          </Box>
        </Grid>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} xs={12}>
              <Box mt={1} className={classes.contentBox}>
                <ImagesSite404 />
              </Box>
            </Grid>

            <Grid item lg={9} md={9} xs={12}>
              <Box mt={3}>
                <Typography className={classes.siteContentbold}>{t('We will be glad to help you')}</Typography>
              </Box>
              <Box mt={1}>
                <Typography className={classes.siteContent}>{t('Possible reason for the 404 error')}</Typography>
              </Box>

              <Box mt={3}>
                <ListingDotBlue
                  title=""
                  listItems={[t('trans.error404-1'), t('trans.error404-2'), t('trans.error404-3')]}
                />
              </Box>
              <Box mt={2}>
                <Typography className={classes.siteContent}>
                  {t('If the error still occurs, please contact us')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
