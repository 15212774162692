import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { TimelineDot } from '@material-ui/lab';
import { useListStyles } from './styles';

export const List = (props) => {
  const { listItems = [], dotColor, size = 'small' } = props;
  const classes = useListStyles({ dotColor, size });

  return (
    <Box>
      {listItems.map((item) => (
        <Box key={item} className={classes.contentList}>
          <TimelineDot className={classes.dot} />
          <Typography>{item}</Typography>
        </Box>
      ))}
    </Box>
  );
};
