import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { List } from '../List';

export function ListingDotBlue(props) {
  const { title, listItems } = props;

  return (
    <>
      <Box mt={-1}>
        <Typography>{title}</Typography>
        <Box>
          <List listItems={listItems} dotColor="#263d86" size="largea" />
        </Box>
      </Box>
    </>
  );
}
