import { makeStyles } from '@material-ui/core/styles';

export const seoStyles = makeStyles(() => ({
  TimelineDot: {
    backgroundColor: '#db0a0a',
    height: '18px',
    width: '18px',
    marginRight: '10px',
    borderBottomStyle: 'solid',
    borderColor: '#efefef',
  },
  siteTitle: {
    fontSize: '2.2rem',
    fontWeight: '600',
    color: '#080c2d',
    marginTop: '20px',
    animation: 'textwelle 0.6s',
  },

  siteTitleContent: {
    fontSize: '2.2rem',
    fontWeight: '600',
    color: '#080c2d',
    marginTop: '20px',
    animation: 'textwelle 0.6s',
  },
  siteContent: {
    fontSize: '1.1rem',
    fontWeight: '400',
    color: '#545372',
    marginLeft: '0px',
  },
  siteContentbold: {
    fontSize: '1.1rem',
    fontWeight: '600',
    color: '#545372',
    marginLeft: '0px',
  },

  contentNotFound: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: '#db0a0a',
    marginLeft: '0px',
  },
  boxImagesHead: {
    minHeight: '300px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '2em 1em 0em 2em',
  },
  contentBox: {
    // minHeight: '100%',
    // height: 'auto',
    width: 'auto',
    padding: '5px 0px 60px 0px',
    // backgroundColor: '#344266',
  },
  boxImagesNoContent: {
    minHeight: '50px',
  },
}));
